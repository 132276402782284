import { MenuEntry } from '@pancakeswap-libs/uikit'

const config: MenuEntry[] = [
  {
    label: 'Home',
    icon: 'HomeIcon',
    calloutClass: 'menulink',
    href: 'https://spidey-farms.pages.dev/',
  },
  {
    label: 'Trade',
    icon: 'TradeIcon',
    calloutClass: 'menulink',
    initialOpenState: true,
    items: [
      {
        label: 'Exchange',
        href: '/swap',
      },
      {
        label: 'Liquidity',
        href: '/pool',
      },
    ],
  },
  {
    label: 'Farms',
    icon: 'FarmIcon',
    calloutClass: 'menulink',
    href: 'https://spidey-farms.pages.dev/farms',
  },
  {
    label: 'Pools',
    icon: 'PoolIcon',
    calloutClass: 'menulink',
    href: 'https://spidey-farms.pages.dev/pools',
  },
  {
    label: 'NFT',
    icon: 'NftIcon',
    calloutClass: 'menulink',
    href: 'https://spidey-nft-frontend.pages.dev/nft',
  },
]

export default config
