import { useEffect } from 'react'
import { CAKE } from '../constants'

const useGetDocumentTitlePrice = () => {
 
  const cakePriceUsd = 0

  useEffect(() => {
    document.title = 'Spidey Barter'
  })
}
export default useGetDocumentTitlePrice
