import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
@import url('https://fonts.googleapis.com/css2?family=Happy+Monkey&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Galada&display=swap');
* {
    font-family: 'Happy Monkey', cursive;
    font-weight: 400 !important;
  }
  body {
    background: url(../images/bg.jpg) ${({ theme }) => theme.colors.background};

    img {
      height: auto;
      max-width: 100%;
    }
  }
.menulink  div {
    font-size: 18px;
    text-transform: uppercase;
  }
div[role="button"] a {
    text-transform: uppercase;
    font-size: 15px;
}
.blueBorder{
    width: 436px;
    max-width: 100%;
  }
.blueBorder > div{
  background: #ffffff !important;
}
`

export default GlobalStyle
